import { useRef } from 'react'
import { Button, Row, Col, Form } from 'react-bootstrap'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'

import { sendForgotPasswordEmail } from 'lib/api/user'
import styles from './ForgotPasswordPage.module.scss'
import { FormikInputFormControl } from 'components/FormikInputFormControl'
import { BootstrapFormikFeedback } from 'components/BootstrapFormikFeedback'
import { UNAUTHORIZED_ROUTES } from 'Routes'
import { useUnauthorizedErrorMessageContext } from '../../hooks/useUnauthorizedErrorMessageContext'
import { normalizeError } from 'utils/commonUtils'
import { validateEmail } from 'utils/validateEmail'

const ForgotPasswordValidation = Yup.object().shape({
  email: Yup.string()
    .test('email', 'Must be a valid email address', validateEmail)
    .required('Please enter your email address'),
})

interface ForgotPasswordPageParams {
  email: string
}

export const ForgotPasswordPage = (): JSX.Element => {
  const { setErrorMessage } = useUnauthorizedErrorMessageContext()

  const history = useHistory()
  const emailRef = useRef<HTMLInputElement | null>(null)

  const onSubmit = async (values: ForgotPasswordPageParams) => {
    try {
      setErrorMessage(null)
      await sendForgotPasswordEmail(values)
      history.push(UNAUTHORIZED_ROUTES.forgotPasswordSuccess, {
        email: values.email,
      })
    } catch (error) {
      setErrorMessage(normalizeError(error).message)
    }
  }

  const navigateToSignIn = () => {
    history.push(UNAUTHORIZED_ROUTES.login)
  }

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={onSubmit}
      validationSchema={ForgotPasswordValidation}
    >
      {({ handleSubmit }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <h2 className={styles.PromptHeading}>Forget your password?</h2>
            <p className={styles.Prompt}>Enter your email and we&rsquo;ll send the instructions.</p>
            <Form.Group controlId="username" className="mb-3">
              <Form.Label>Enter email address:</Form.Label>
              <FormikInputFormControl name="email" placeholder="Your email" ref={emailRef} />
              <BootstrapFormikFeedback name="email" />
            </Form.Group>
            <Row>
              <Col sm={6}>
                <Button className="w-100" style={{ marginTop: 10 }} type="submit">
                  Send Reset Instructions
                </Button>
              </Col>
              <Col sm={6} style={{ marginTop: 10 }}>
                <Button className="w-100" onClick={navigateToSignIn}>
                  Back to Sign in
                </Button>
              </Col>
            </Row>
          </Form>
        )
      }}
    </Formik>
  )
}
